import { Helmet } from 'react-helmet';
import SectionPageTitle from '../components/SectionPageTitle';
import SectionThemes from '../components/SectionThemes';

export default function Themes() {
	return (
		<>
			<Helmet>
				<title>Quick and Easy Website Template &bull; Travolgi Themes</title>
				<meta name="description" content="Choose the right theme for you: We defy the ordinance and do everything we can to focus on creativity to create well-designed sites that can impress people with different tastes." />
				<meta name="keywords" content="travolgi themes, themes, web, html, react, ui element, web designs, react js, user interface elements, elementor, elementor kit, elementor template, wordpress elementor, html, html5, multipurpose template, multipurpose website, uo kit, adobe xd, figma, modern design, web design, themes, have a website, website, wordpress website, corporate website, responsive website, responsive, template, templatemonster, easily, quickly, documentation and support, pre-packaged themes, performing website, low cost" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Themes'} 
				subTitle={'Focus on creativity'}
			/>

			<SectionThemes />
		</>
	);
}
